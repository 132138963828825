import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Sending a streaming response with back pressure";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "sending-a-streaming-response-with-back-pressure",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#sending-a-streaming-response-with-back-pressure",
        "aria-label": "sending a streaming response with back pressure permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sending a streaming response with back pressure`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#sending-a-file-with-back-pressure"
        }}>{`Sending a file with back pressure`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#sending-a-streaming-response-using-httpresponsewriter"
        }}>{`Sending a streaming response using HttpResponseWriter`}</a></li>
    </ul>
    <p>{`Suppose we want to serve a static file whose size is larger than your available memory. If we load the file into the memory at once, we will definitely get an `}<inlineCode parentName="p">{`OutOfMemoryError`}</inlineCode>{`.`}</p>
    <p>{`To prevent such error and send large files without consuming too much memory:`}</p>
    <ol>
      <li parentName="ol">{`Divide the file into chunks.`}</li>
      <li parentName="ol">{`Load the first chunk into memory.`}</li>
      <li parentName="ol">{`Send the chunk to the client.`}</li>
      <li parentName="ol">{`Wait until the chunk is written to the sending socket buffer. (See below for explanation.)`}</li>
      <li parentName="ol">{`Load the second chunk and repeat the steps 3 and 4 until we send all chunks.`}</li>
    </ol>
    <p>{`Waiting for the chunk to be written is to avoid loading data into memory when the client is not ready to receive. This is called `}<strong parentName="p">{`back pressure`}</strong>{`.
See `}<a parentName="p" {...{
        "href": "https://engineering.linecorp.com/en/blog/reactive-streams-armeria-1/"
      }}>{`Let’s Play with Reactive Streams on Armeria - 1`}</a>{` to learn the conditions under which an `}<inlineCode parentName="p">{`OutOfMemoryError`}</inlineCode>{` is raised and back pressure which can help you with the error.`}</p>
    <h2 {...{
      "id": "sending-a-file-with-back-pressure",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#sending-a-file-with-back-pressure",
        "aria-label": "sending a file with back pressure permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sending a file with back pressure`}</h2>
    <p>{`To send a file with back pressure, use `}<a parentName="p" {...{
        "href": "type://HttpFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html"
      }}>{`type://HttpFile`}</a>{`, which loads and sends file chunks one by one to the client with back pressure.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.Server;
import com.linecorp.armeria.server.file.HttpFile;

HttpFile bigFile = HttpFile.of(new File("/var/www/big_file.dat"));
Server.builder()
      .service("/big_file.dat", bigFile.asService())
      .build();
`}</code></pre>
    <h2 {...{
      "id": "sending-a-streaming-response-using-httpresponsewriter",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#sending-a-streaming-response-using-httpresponsewriter",
        "aria-label": "sending a streaming response using httpresponsewriter permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sending a streaming response using HttpResponseWriter`}</h2>
    <p>{`To send large data other than files such as database, you need to implement back pressure yourself. Let's start off with implementing a minimal `}<a parentName="p" {...{
        "href": "type://HttpFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html"
      }}>{`type://HttpFile`}</a>{`.`}</p>
    <p>{`Prepare to send a streaming response with `}<a parentName="p" {...{
        "href": "type://HttpResponseWriter:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponseWriter.html"
      }}>{`type://HttpResponseWriter`}</a>{` returned by `}<a parentName="p" {...{
        "href": "type://HttpResponse#streaming():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html#streaming()"
      }}>{`type://HttpResponse#streaming()`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.HttpData;
import com.linecorp.armeria.common.HttpResponse;
import com.linecorp.armeria.common.HttpResponseWriter;
import com.linecorp.armeria.common.ResponseHeaders;
import com.linecorp.armeria.server.ServerBuilder;

// ⚠️ This code has a problem. Do not copy/paste and use it.
ServerBuilder sb = ...;
sb.service("/big_file.dat", (ctx, req) -> {
    HttpResponseWriter response = HttpResponse.streaming();
    // We must write the response headers first.
    response.write(ResponseHeaders.of(200));
    response.write(produceChunk(0));
    response.write(produceChunk(1));
    response.write(produceChunk(2));
    ... // Write more chunks until we send all chunks.
    // Call close() to end the response.
    response.close();
    return response;
}

...
private HttpData produceChunk(int index) {
    // Divide the file by the pre-defined chunk size(e.g. 8192 bytes)
    // and read it using index.
    // If index is 0, 0 to 8192 bytes from the file is read.
    // If index is 1, 8193 to 16384 is read and so on.
}
`}</code></pre>
    <p>{`With the code above, the server would encounter `}<inlineCode parentName="p">{`OutOfMemoryError`}</inlineCode>{`. We still need to take care of preventing loading data chunks into memory before a chunk is sent to the client. To solve the problem, implement back pressure with `}<a parentName="p" {...{
        "href": "type://StreamWriter#whenConsumed():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamWriter.html#whenConsumed()"
      }}>{`type://StreamWriter#whenConsumed()`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`sb.service("/big_file.dat", (ctx, req) -> {
    HttpResponseWriter response = HttpResponse.streaming();
    response.write(ResponseHeaders.of(200));
    response.whenConsumed().thenRun(() -> {
        // Produce the first chunk when the ResponseHeaders is
        // written to the socket.
        response.write(produceChunk(0));
        response.whenConsumed().thenRun(() -> {
            // Produce the second chunk when the first chunk is
            // written to the socket.
            response.write(produceChunk(1));
            ...
        });
    });
    return response;
});
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "type://StreamWriter#whenConsumed():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamWriter.html#whenConsumed()"
      }}>{`type://StreamWriter#whenConsumed()`}</a>{` returns a `}<inlineCode parentName="p">{`CompletableFuture`}</inlineCode>{` that is complete when the chunk written to the `}<a parentName="p" {...{
        "href": "type://HttpResponseWriter:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponseWriter.html"
      }}>{`type://HttpResponseWriter`}</a>{` is finally written to the socket. This enables you to add the next task by adding a callback (`}<inlineCode parentName="p">{`thenRun()`}</inlineCode>{` in the code example). The next task in the example is set to produce the next chunk.`}</p>
    <Tip mdxType="Tip">
      <p>{`See `}<a parentName="p" {...{
          "href": "https://engineering.linecorp.com/en/blog/reactive-streams-armeria-2/"
        }}>{`Let’s Play with Reactive Streams on Armeria - 2`}</a>{` to learn how network layer plays in with back pressure.`}</p>
    </Tip>
    <p>{`Of course, we would need to use recursion to be free from infinite implementation of the callback.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`sb.service("/big_file.dat", (ctx, req) -> {
    HttpResponseWriter response = HttpResponse.streaming();
    response.write(ResponseHeaders.of(200));
    streamingResponse(response, 0);
    return response;
});

private void streamingResponse(HttpResponseWriter response, int index) {
    if (isEndOfFile()) {
        // Close the response when we send all chunks.
        response.close();
        return;
    }
    response.whenConsumed().thenRun(() -> {
        if (response.tryWrite(produceChunk(index))) {
            streamingResponse(response, index + 1);
        } else {
            // The response is completed unexpectedly.
        }
    });
}
`}</code></pre>
    <p>{`So far, we have implemented a simple version of `}<a parentName="p" {...{
        "href": "type://HttpFile:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/HttpFile.html"
      }}>{`type://HttpFile`}</a>{`. Now, we can implement a streaming response with back pressure for any type of source (e.g. database) by simply changing the `}<inlineCode parentName="p">{`produceChunk()`}</inlineCode>{` method to fetch data from the source.`}</p>
    <p>{`You have other alternatives to implement back pressure with; there are libraries such as `}<a parentName="p" {...{
        "href": "https://projectreactor.io"
      }}>{`Reactor`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/ReactiveX/RxJava"
      }}>{`RxJava`}</a>{`. If you opt to use such alternative,
return the response using `}<a parentName="p" {...{
        "href": "type://HttpResponse#of(ResponseHeaders,Publisher):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html#of(com.linecorp.armeria.common.ResponseHeaders,org.reactivestreams.Publisher)"
      }}>{`type://HttpResponse#of(ResponseHeaders,Publisher)`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`sb.service("/big_data.dat", (ctx, req) -> {
    Flux<HttpData> flux = ... // Fetch data from other source with backpressure.
    return HttpResponse.of(ResponseHeaders.of(200), flux);
});
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`Have a go at experimenting with a rather `}<a parentName="p" {...{
          "href": "https://github.com/line/armeria-examples/blob/main/proxy-server/src/main/java/example/armeria/proxy/AnimationService.java"
        }}>{`fun working example`}</a>{` that sends a streaming response with back pressure.`}</p>
    </Tip>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      